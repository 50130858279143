.video-item-loading-background {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  background-repeat: no-repeat;
  background-size: 728px 89px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -728px 0;
  }
  100% {
    background-position: 728px 0;
  }
}
